const version = "2.0.5 🤦";

const getGeoData = async () => {
  const apiKey = "55ac4c394f2c769c39b0e620919e6cc21077fb6cdefe6d9e74b5f845";
  const endpoint = `https://api.ipdata.co/?api-key=${apiKey}`;
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
  const json = await response.json();

  return {
    ispName: json.asn?.name,
    continent: json.continent_name,
    country: json.country_name,
    region: json.region,
    isEU: json.is_eu,
  };
};

async function createUser(location) {
  const now = Math.floor(Date.now() / 1000);
  const params = new URLSearchParams(location.search);
  const geoData = await getGeoData();

  return {
    session: {
      history: [],
      utm: {
        source: params.get("utm_source"),
        medium: params.get("utm_medium"),
        content: params.get("utm_content"),
        campaign: params.get("utm_campaign"),
      },
      initialReferrer: document.referrer.includes(process.env.GATSBY_SITE)
        ? undefined
        : document.referrer,
      initialLanding: location.href,
      expiry: now + 86_400,
      geo: geoData,
    },

    version,
  };
}

async function loadUserFromLocalStorage(location) {
  let user = window.localStorage.getItem("marketing-user");
  if (user !== null) {
    user = JSON.parse(user);
  } else {
    user = await createUser(location);
  }
  return user;
}

function saveUserToLocalStorage(user) {
  window.localStorage.setItem("marketing-user", JSON.stringify(user));
  window.dispatchEvent(new Event("storage"));
}

export async function initUser(location) {
  let user = await loadUserFromLocalStorage(location);
  const now = Math.floor(Date.now() / 1000);

  if (user.version !== version || now > user.expiry) {
    user = await createUser(location);
    window.localStorage.clear();
  }

  if (user.session.history.at(-1) !== location.href) {
    user.session.history.push(location.href);
  }

  saveUserToLocalStorage(user);
}

async function appendToHistory(url) {
  let user = await loadUserFromLocalStorage();
  user.session.history.push(url);
  saveUserToLocalStorage(user);
}

export async function onLinkClick(event) {
  const el = event.target.closest("a");

  if (el !== null && el.href.startsWith("http")) {
    await appendToHistory(el.href);
    if (
      ["oee.com", "leanproduction.com"].includes(process.env.GATSBY_SITE) &&
      el.href.includes("vorne.com")
    ) {
      event.preventDefault();
      window.open(el.href, "_blank");
    }
  }
}

const merge = async (incoming, location) => {
  let user = await loadUserFromLocalStorage(location);

  if (user.override !== true) {
    user = {
      ...incoming,
      session: {
        ...incoming.session,
        vorneReferrer: incoming.session.history.at(-2),
        vorneLanding: user.session.initialLanding,
      },
      override: true,
    };
    saveUserToLocalStorage(user);
  }
};

export const onMessageHandler = async (event) => {
  switch (event?.data?.type) {
    case "vorne-marketing-user-request": {
      if (event.origin !== location.origin) {
        const user = await loadUserFromLocalStorage();
        event.source.postMessage(
          {
            type: "vorne-marketing-user-response",
            user,
          },
          "*",
        );
      }
      break;
    }
    case "vorne-marketing-user-response": {
      merge(event.data.user, location);
      break;
    }
  }
};
