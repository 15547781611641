import { createGlobalStyle } from "styled-components";
import BigIdea from "../components/bigidea/bigIdea";
import CallToAction from "../components/button/calltoaction/callToAction";
import {
  Heading1,
  Heading2,
  Heading3,
  Body1,
  Heading2SubHead,
} from "../components/typography/typography";
import PullQuote from "../components/pullquote/pullQuote";
import List from "../components/list/v1";
import Image from "../components/image/image";
import Snippet from "../components/snippet/snippet";
import Section from "../components/section/section";
import { Table } from "../components/table/table";
import Card from "../components/card/card";
import Grid from "../components/layout/grid/grid";
import QuickLinks from "../components/quicklinks/quickLinks";
import Link from "../components/link/link";
import Center from "../components/layout/center/center";
import Spec from "../components/spec/spec";
import { Formula } from "../components/formula/formula";

const GlobalStyleSheet = createGlobalStyle`
  html,body, ___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  // General Rules

  figcaption {
    margin-top: 10px;
  }

  ${BigIdea} {
    margin-top: 45px;
  }

  ${QuickLinks} {
    margin-top: 25px;
  }


  ${CallToAction} {
    margin-top: 40px;

  }

  * + ${Body1} {
  margin-top: 20px;
  }
  
  * + ${List} {
    margin-top: 15px;
  }

  ${List} + * {
    margin-top: 15px;
  }

  * + ${Snippet} {
    margin-top: 30px;
  }


  // Component Overrides
  
  * + ${PullQuote} {
    margin-top: 40px;
  }

  ${PullQuote} + * {
    margin-top: 40px;
  }


  [data-component-name="banner-ad"] + * {
    margin-top: 50px;
  }

  * + [data-component-name="banner-ad"] {
    margin-top: 40px;
  }

  ${Center} > ${Table} {
    margin-top: 20px;
  }

  ${Table} {
    td {
      ${Image} {
        margin-top: 0px;
      }
    }
  }

  ${Table} + * {
    margin-top: 20px;
  }

  * + ${Table} {
    margin-top: 20px;
  }

  ${Center} > ${Link} > ${Image}, ${Center} > ${Image} {
    margin-top: 40px;
  }

  ${Section} {
    ${Image} {
      margin-top: 40px;
    }
  }

  ${Center} + * {
    margin-top: 40px;
  }

  * + ${Heading1} {
    margin-top: 50px;
  }

  * + ${Heading3} {
    margin-top: 15px;
  }

  ${Heading3} + * {
   margin-top: 5px;
  }

  ${Heading1} + ${Body1} {
    margin-top: 5px;
  }

  ${Heading2} + ${Body1} {
    margin-top: 5px;
  }
  
  ${Heading2SubHead} + * {
    margin-top: 5px;
  }

  [data-component-name="banner-ad"] + ${Heading1} {
    margin-top: 50px;
  }

  * + ${Heading2} {
    margin-top: 30px;
  }

  ${Snippet} > ${Image} {
    margin-top: 0px;
  }

  [data-component-name="vorne-homepage-hero"] {
    ${Heading1} {
      margin-top: 0px;
    }
  }

  ${Section} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${PullQuote} + ${Center} {
    margin-top: 0px;
  }

  ${Center} + ${Center} {
    margin-top: 0px;
  }

  * + ${Card} {
    margin-top: 20px;
  }

  * + ${Grid} {
    margin-top: 40px;
  }

  ${Grid} {
    ${Card} {
      margin-top: 0px;
    }

    ${Image} {
      margin-top: 0px;
    }
  }

  

  ${Spec} {
    margin-top: 40px;
  }

  ${Formula} + * {
    margin-top: 40px;
  }

  * + ${Formula} {
    margin-top: 40px;
  }

  ${Snippet} + * {
  margin-top: 30px;
}


`;

export default GlobalStyleSheet;
